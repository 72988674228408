import { memo } from "react"
import { Container, Divider, Heading, Box } from "@chakra-ui/react"
import { StyleGuideTypography } from "./StyleGuideTypography"
import { StyleGuideBreakpoints } from "./StyleGuideBreakpoints"
import { StyleGuideColors } from "./StyleGuideColors"
import { StyleGuideIcons } from "./StyleGuideIcons"
import { StyleGuideBadges } from "./StyleGuideBadges"
import { StyleGuideForms } from "./StyleGuideForms"
import { StyleGuideButtons } from "./StyleGuideButtons"
import { StyleGuideImageRatios } from "./StyleGuideImageRatios"
import { StyleGuideTags } from "./StyleGuideTags"

import type { PageProps } from "@root/types/global"

const SectionTitle = ({ children }: { children: any }) => (
  <Box mt={10} mb={4}>
    <Divider mb={4} />
    <Heading as="h3" size="h3" color="brand.error" textDecoration="underline">
      {children}
    </Heading>
  </Box>
)

const StyleGuide: React.FC<PageProps<any>> = () => {
  return (
    <Box bgColor="brand.offWhite">
      <Container pb={4} pt={18}>
        <Heading as="h1" size="h1" mb="8" textTransform="uppercase">
          Style Guide
        </Heading>

        <SectionTitle>Colors</SectionTitle>
        <StyleGuideColors />

        <SectionTitle>Typography</SectionTitle>
        <StyleGuideTypography />

        <SectionTitle>Breakpoints</SectionTitle>
        <StyleGuideBreakpoints />

        <SectionTitle>Forms</SectionTitle>
        <StyleGuideForms />

        <SectionTitle>Buttons</SectionTitle>
        <StyleGuideButtons />

        <SectionTitle>Icons</SectionTitle>
        <StyleGuideIcons />

        <SectionTitle>Badges</SectionTitle>
        <StyleGuideBadges />

        <SectionTitle>Tags</SectionTitle>
        <StyleGuideTags />

        <SectionTitle>Image Ratios</SectionTitle>
        <StyleGuideImageRatios />
      </Container>
    </Box>
  )
}

export default memo(StyleGuide)
