import { Flex, Box, Heading, Text, useTheme } from "@chakra-ui/react"

const ColourSwatch = ({ color }: { color: string }) => {
  const { colors } = useTheme()

  return (
    <Box>
      <Box
        w="168px"
        h="168px"
        bg={`brand.${color}`}
        mr="24px"
        mb="10px"
        alignItems="center"
        justifyContent="center"
        border="2px"
        borderColor="apricot"
        textShadow="1px 1px 10px white"
      />
      <Text>{color}</Text>
      <Text>{colors.brand?.[color]}</Text>
    </Box>
  )
}

export const StyleGuideColors = () => {
  const primaryColors = ["peachy", "avocado", "lavender", "rain"]
  const secondaryColors = ["offWhite", "lightSand", "darkerSand", "sand", "midSand", "apricot", "apricotSand"]
  const tertiaryColors = ["yellow", "disabledLight", "green30", "lighterGreen", "error", "accepted"]

  return (
    <>
      <Heading as="h4" size="h4" mt={6}>
        Primary
      </Heading>
      <Flex wrap="wrap">
        {primaryColors.map(color => (
          <ColourSwatch key={color} color={color} />
        ))}
      </Flex>
      <Heading as="h4" size="h4" mt={6}>
        Secondary
      </Heading>
      <Flex wrap="wrap">
        {secondaryColors.map(color => (
          <ColourSwatch key={color} color={color} />
        ))}
      </Flex>
      <Heading as="h4" size="h4" mt={6}>
        Tertiary
      </Heading>
      <Flex wrap="wrap">
        {tertiaryColors.map(color => (
          <ColourSwatch key={color} color={color} />
        ))}
      </Flex>
    </>
  )
}
