import { Tag, HStack, TagLabel, TagCloseButton } from "@chakra-ui/react"

export const StyleGuideTags = () => (
  <HStack my={4} wrap="wrap" gap={2} bg="brand.apricot" p={4}>
    <Tag variant="date">Date Tag</Tag>
    <Tag variant="product-card">Product Card Badge</Tag>
    <Tag variant="product-variant-selector">Variant Selector</Tag>
    <Tag variant="subcategory">Subnavigation Category</Tag>
    <Tag variant="subcategory-active">
      <TagLabel>Subnavigation Category Active</TagLabel>
      <TagCloseButton />
    </Tag>
    <Tag variant="pdp-description">PDP Description</Tag>
    <Tag variant="nutritionist-hero">Nutritionist Hero</Tag>
    <Tag variant="search-suggested">Nutritionist Hero</Tag>
  </HStack>
)
