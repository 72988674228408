import {
  ArrowForwardIcon,
  ArrowBackIcon,
  ArrowUpIcon,
  LinkIcon,
  CloseIcon,
  ChevronDownIcon,
  AddIcon,
  MinusIcon,
  QuestionOutlineIcon,
  SearchIcon,
  InfoOutlineIcon,
  CheckIcon,
} from "@chakra-ui/icons"
import { FaTiktok, FaFacebookF, FaInstagram } from "react-icons/fa"
import { BsPinterest, BsTwitter } from "react-icons/bs"
import { BiSortAlt2 } from "react-icons/bi"
import { CgMenu } from "react-icons/cg"
import { FiShare2 } from "react-icons/fi"
import Truck from "@static/images/truck.png"
import AusFlag from "@static/images/aus-flag.png"
import { CustomIcon } from "@components/Icon"
import { Flex, Box, Icon, Image, Heading } from "@chakra-ui/react"

const PaddingIcon = ({ name, boxOnly, children }: any) => <Box m={2}>{boxOnly ? children : <CustomIcon name={name} />}</Box>

export const StyleGuideIcons = () => (
  <>
    <Box mb={10}>
      <Heading as="h4" size="h4" textDecoration="underline">
        Brand Logo
      </Heading>
      <CustomIcon name="logo" width={200} height={60} />
    </Box>

    <Box mb={10}>
      <Heading as="h4" size="h4" textDecoration="underline">
        Social Icons
      </Heading>
      <Flex wrap="wrap" mt={2}>
        <PaddingIcon boxOnly>
          <Icon as={FaInstagram} />
        </PaddingIcon>
        <PaddingIcon boxOnly>
          <Icon as={FaTiktok} />
        </PaddingIcon>
        <PaddingIcon boxOnly>
          <Icon as={FaFacebookF} />
        </PaddingIcon>
        <PaddingIcon boxOnly>
          <Icon as={BsPinterest} />
        </PaddingIcon>
        <PaddingIcon boxOnly>
          <Icon as={BsTwitter} />
        </PaddingIcon>
      </Flex>
    </Box>

    <Box mb={10}>
      <Heading as="h4" size="h4" textDecoration="underline">
        UI Icons
      </Heading>
      <Flex wrap="wrap" mt={2}>
        <ArrowBackIcon m={2} w={5} h={5} />
        <ArrowForwardIcon m={2} w={5} h={5} />
        <ArrowUpIcon m={2} w={5} h={5} />
        <LinkIcon m={2} w={5} h={5} />
        <CloseIcon m={2} w={5} h={5} />
        <ChevronDownIcon m={2} w={5} h={5} />
        <AddIcon m={2} w={5} h={5} />
        <MinusIcon m={2} w={5} h={5} />
        <SearchIcon m={2} w={5} h={5} />
        <InfoOutlineIcon m={2} w={5} h={5} />
        <QuestionOutlineIcon m={2} w={5} h={5} />
        <CheckIcon m={2} w={5} h={5} />
        <PaddingIcon name="shopping-list" />
        <PaddingIcon name="shopping-list-gm" />
        <PaddingIcon name="cart" />
        <PaddingIcon name="leaf" />
        <PaddingIcon name="heart-off" />
        <PaddingIcon name="radio-checked" />
        <PaddingIcon name="radio-unchecked" />
        <PaddingIcon boxOnly>
          <Image src={AusFlag} w={3} h={3} />
        </PaddingIcon>
        <PaddingIcon boxOnly>
          <Icon as={BiSortAlt2} />
        </PaddingIcon>
        <PaddingIcon boxOnly>
          <Icon as={CgMenu} />
        </PaddingIcon>
        <PaddingIcon name="settings-adjust" />
        <PaddingIcon boxOnly>
          <Image src={Truck} w={6} h={4} />
        </PaddingIcon>
        <PaddingIcon boxOnly>
          <Icon as={FiShare2} />
        </PaddingIcon>
        <PaddingIcon name="trash" />
        <PaddingIcon name="star" />
        <PaddingIcon name="star-outline" />
      </Flex>
    </Box>
  </>
)
