import { Container, Input, Textarea, Flex, useRadioGroup, Text, Box, HStack } from "@chakra-ui/react"
import { CustomRadio } from "@components/Radio"
import { CustomSelect } from "@components/Select"
import { WishListButton } from "@components/WishList/WishListButton"

function ExampleRadioGroup() {
  const list = ["one", "two", "three"]
  const { getRootProps, getRadioProps } = useRadioGroup({
    name: "test",
    defaultValue: "two",
    onChange: console.info,
  })

  const group = getRootProps()

  return (
    <>
      <Text mt={5}>Do you recommend this product?</Text>
      <HStack {...group}>
        {list.map(item => (
          <CustomRadio key={item} {...getRadioProps({ value: item })}>
            {item}
          </CustomRadio>
        ))}
      </HStack>
    </>
  )
}

const exampleSelectOptions = [
  {
    label: "Sample my product in the GoodnessMe Box",
  },
  {
    label: "Review a GoodnessMe Box",
  },
  {
    label: "Advertise",
  },
  {
    label: "Let’s Collaborate",
  },
  {
    label: "Media Enquiry",
  },
  {
    label: "Affiliate",
  },
]

const exampleSelectOptionsWithValues = exampleSelectOptions.map(({ label }) => ({
  label,
  value: label.toLowerCase().replaceAll(" ", "-"),
}))

const Placeholder = () => (
  <p>
    <span style={{ fontWeight: "bold" }}>Enquiry type:</span> Sample my product in the GoodnessMe Box
  </p>
)

export const StyleGuideForms = () => {
  return (
    <Container>
      <Flex wrap="wrap" justifyContent="space-between">
        <Box w="32%" mb={2}>
          <Text mr={4}>Default Text + Hover + Active</Text>
          <Input placeholder="Name" mr={1} />
        </Box>
        <Box w="32%" mb={2}>
          <Text mr={4}>Email</Text>
          <Input placeholder="Email" type="email" mr={1} />
        </Box>
        <Box w="32%" mb={2}>
          <Text mr={4}>Password</Text>
          <Input placeholder="Password" type="password" mr={1} />
        </Box>
        <Box w="32%" mb={2}>
          <Text mr={4}>Disabled</Text>
          <Input placeholder="Name" mr={1} isDisabled />
        </Box>
        <Box w="32%" mb={2}>
          <Text mr={4}>Error</Text>
          <Input placeholder="Name" mr={1} isInvalid />
        </Box>
        <Box w="32%" mb={2}>
          <Text mr={4}>Correct</Text>
          <Input placeholder="Name" mr={1} variant="correct" />
        </Box>
      </Flex>
      <Text>Text Area</Text>
      <Textarea mb={2} w="full" placeholder="Message" />
      <Text>Dropdown</Text>
      <CustomSelect options={exampleSelectOptionsWithValues} placeholder={<Placeholder />} />
      <ExampleRadioGroup />

      <Box bg="brand.midSand">
        <Box mt={4} w="226px">
          <Text fontWeight={700}>Large (PDP) on desktop</Text>
          <WishListButton fontSize="xl" height={12} iconTextGap={3} />
          <Text fontWeight={700} mt={4}>
            Large (PDP) on mobile
          </Text>
          <WishListButton fontSize="md" height={12} iconTextGap={2.5} />
        </Box>
        <Box mt={4} width="210px">
          <Text fontWeight={700}>Product Card</Text>
          <WishListButton fontSize="sm" height={10} iconWidth={16} iconTextGap={2.5} />
        </Box>
        <Text fontWeight={700} mt={4}>
          PDP mobile top bar dropdown
        </Text>
        <Box width="120px">
          <WishListButton fontSize="md" height={7} iconTextGap={2.5} showUnderline showOutline={false} showBgColor={false} />
        </Box>
      </Box>
    </Container>
  )
}
