import { Flex, Image } from "@chakra-ui/react"
import { CustomIcon } from "@components/Icon"

import Avocado from "@static/images/avocado.png"
import Orange from "@static/images/orange.png"
import Pear from "@static/images/pear.png"
import Pear2 from "@static/images/pear2.png"
import Peach from "@static/images/peach.png"
import Eucalyptus from "@static/images/eucalyptus.png"
import FoodBox from "@static/images/food-box.png"

export const StyleGuideBadges = () => (
  <Flex wrap="wrap" mt={2}>
    <Image src={Avocado} w={14} />
    <Image src={Orange} w={14} />
    <Image src={Eucalyptus} w={14} />
    <Image src={Peach} w={14} />
    <Image src={Pear} w={14} />
    <Image src={Pear2} w={14} />
    <Image src={FoodBox} w={14} />
    <CustomIcon name="account-points" width={56} height={56} />
  </Flex>
)
