import { useBreakpointValue, Flex, Text } from "@chakra-ui/react"

export const StyleGuideBreakpoints = () => {
  const breakpoint = useBreakpointValue({
    base: {
      boxBg: "brand.yellow",
      boxTxt: "'Small' screen (from 0 to 599px)",
    },
    md: {
      boxBg: "brand.peachy",
      boxTxt: "'Medium' scren (from 600px to 899px)",
    },
    lg: {
      boxBg: "brand.rain",
      boxTxt: "'Large' screen (from 900px to 1535px)",
    },
    xl: {
      boxBg: "brand.lavender",
      boxTxt: "'Extra Large' screen (from 1536px+)",
    },
  })

  return (
    <>
      <Flex
        w="full"
        h="100px"
        bg={breakpoint?.boxBg}
        border="2px"
        borderColor="brand.avocado"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
      >
        <Text>Change the screen size to see the breakpoints change.</Text>
        <Text>Current breakpoint: {breakpoint?.boxTxt}</Text>
      </Flex>
    </>
  )
}
