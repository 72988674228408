import { Flex, Heading, Box } from "@chakra-ui/react"
import { CustomImage } from "@components/Image"

export const StyleGuideImageRatios = () => (
  <Flex wrap="wrap" mt={2}>
    <Box mr={5}>
      <Heading size="h5">Portrait</Heading>
      <CustomImage src="" bg="brand.rain" width="360px" aspectRatio="portrait" />
    </Box>
    <Box mr={5}>
      <Heading size="h5">Square</Heading>
      <CustomImage src="" bg="brand.rain" width="360px" aspectRatio="square" />
    </Box>
    <Box mr={5}>
      <Heading size="h5">Landscape</Heading>
      <CustomImage src="" bg="brand.rain" width="440px" aspectRatio="landscape" />
    </Box>
  </Flex>
)
