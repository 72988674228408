import { Flex, Box, Text, useRadio, UseRadioProps } from "@chakra-ui/react"
import { CustomIcon } from "@components/Icon"

interface CustomRadioProps extends UseRadioProps {
  children: string | React.ReactNode
}

export function CustomRadio(props: CustomRadioProps) {
  const { getInputProps, getCheckboxProps } = useRadio(props)
  const input = getInputProps()
  const checkbox = getCheckboxProps()

  return (
    <Box as="label">
      <input {...input} />
      <Flex {...checkbox} cursor="pointer" justifyContent="center" alignItems="center">
        {input.checked ? (
          <CustomIcon name="radio-checked" width={17} height={17} />
        ) : (
          <CustomIcon name="radio-unchecked" width={17} height={17} />
        )}
        <Text mr={1} ml={2.5}>
          {props.children}
        </Text>
      </Flex>
    </Box>
  )
}
