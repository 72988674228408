import { Text, Heading, Stack } from "@chakra-ui/react"
import { CustomLink } from "../Link"

export const StyleGuideTypography = () => {
  const exampleString = "The quick brown fox jumps over the lazy dog. "

  return (
    <Stack>
      <Heading as="h1" size="h1">
        H1: {exampleString}
      </Heading>
      <Heading as="h2" size="h2">
        H2: {exampleString}
      </Heading>
      <Heading as="h3" size="h3">
        H3: {exampleString}
      </Heading>
      <Heading as="h4" size="h4">
        H4: {exampleString}
      </Heading>
      <Heading as="h5" size="h5">
        H5: {exampleString}
      </Heading>
      <Heading as="h6" size="h6">
        H6: {exampleString}
      </Heading>
      <Text variant="overline">overline: {exampleString}</Text>
      <Text>body: {Array.from({ length: 5 }).map(() => exampleString)}</Text>
      <Text size="lg">body: {Array.from({ length: 5 }).map(() => exampleString)}</Text>
      <CustomLink to="https://example.com">Example link</CustomLink>
      <Text variant="label">small label: {exampleString}</Text>
    </Stack>
  )
}
